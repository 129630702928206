<template>
  <!-- 讲师宣传 -->
  <div class="promotion_container">
    <!-- 顶部栏 -->
    <Header />
    <div class="main_container">
      <!-- 顶部图片 -->
      <div class="top_bg">
        <div class="top_bg_main">
          <div class="left_text">
            <p>
              Become a <br>
              Rakumart Promoter
            </p>
            <p>
              Earn commissions by inviting new customers and <br>
              place orders. Rakumart Promoter Plan offers an opportunity<br>
              for promoters to earn commissions by inviting US customers<br>
              to purchase Chinese products from <span>
                Rakumart.us.
              </span>
            </p>
            <el-button @click="toRegister">
              Apply now
            </el-button>
          </div>
          <div class="right_img">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d83c4ba5e63.png" alt="">
          </div>
        </div>

      </div>
      <!-- 表格区域 -->
      <div class="table_container">
        <div class="table_main">
          <!-- 固定导航盒子 -->
          <div id="navId" class="fixed_nav">
            <ul>
              <li v-for="(item, index) in navList" :key="item.id" :class="{ navActived: navIndex == index }"
                @click="toAchor(item, index)">
                {{ item.title }}
              </li>
            </ul>
          </div>
          <div class="main_title" id="cooperation">
            Advantages of cooperation
          </div>
          <div class="first_table">
            <div class="first_table_left">
              <p>
                High commission returns, easily increase income
              </p>
              <p>
                For goods shipped within the year after customer registration, you can <br>
                receive a commission of 2% of the total amount of goods. In the <br>
                second year, you can receive a commission of 1.5%. In the third year, <br>
                you can receive a commission of 1%.
              </p>
              <p style="cursor: pointer;" @click="isShowAgreement = true">
                <!-- https://www.rakumart.us/register -->
                Promoter Service Agreement
              </p>
            </div>
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66f4cc060ab7d.svg" alt="">
          </div>
          <div class="second_table">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66f4cc5e8caa7.svg" alt="">
          </div>
          <div class="third_table">
            <!-- 轮播图行 -->
            <div class="scroll_box">
              <el-carousel trigger="click" arrow="never" indicator-position="outside">
                <el-carousel-item v-for="item in carouselListImg" :key="item.id">
                  <img :src="item.url" alt="">
                </el-carousel-item>
              </el-carousel>
              <div class="right_text">
                <p>
                  Exclusive Promoter Management System
                </p>
                <p>
                  Easily track your promotion performance, view details of invited customers, their orders, and
                  accumulated commissions
                </p>
              </div>
            </div>
            <!-- 视频行 -->
            <div class="vedio_box">
              <div class="vedio_text">
                <p>
                  Rich promotional materials
                </p>
                <p>
                  You can subscribe to our YouTube channel:
                  RakumartUS, which
                  features numerous videos
                  about
                  <a style="color: #FF730B;" href="https://www.rakumart.us/" target="_blank">Rakumart.us</a>, including
                  additional services and
                  the complete order processing process. Initially, you
                  can use these materials, and once you become familiar with the promotional process, you can create
                  your own relevant promotional materials.
                  Here is the Youtube channel link:
                  <a style="color: #FF730B;" href="https://www.youtube.com/@RakumartUS"
                    target="_blank">https://www.youtube.com/@RakumartUS</a>
                </p>
              </div>

              <div class="videoBox">
                <div class="edy-texteditor-container embed-container edy-positionable-container-maxwidth"
                  contenteditable="false" unselectable="on" style="
            max-width: 100%;
            overflow: hidden;
            position: relative;
            width: auto;
          ">
                  <iframe id="ifrId" width="560" height="315" style="border-radius: 20px;"
                    src="https://www.youtube.com/embed/pvPoIFyYaH0?autoplay=1&mute=1&si=swCHvDhsdgOSE6nj"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 流程 -->
        <div class="table_main">
          <div class="main_title process_box_title" id="process">
            Cooperation process
          </div>
          <div class="process_item">
            <div class="process_item_title">
              Commission Acquisition Process
            </div>
            <div class="process_item_content">
              <div class="img_box">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6d475a3da.svg" alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6d6132a32.svg" alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6dd423936.svg" alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6d6132a32.svg" alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6e170a449.svg" alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6d6132a32.svg" alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6e69c38d2.svg" alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6d6132a32.svg" alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6e8394e03.svg" alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6d6132a32.svg" alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6e9dc3389.svg" alt="">
              </div>
              <div class="text_box">
                <p>
                  Copy invitation link
                </p>
                <p>
                  Share invitation link
                </p>
                <p>
                  Invited customer registration
                </p>
                <p>
                  Customers place orders for goods
                </p>
                <p>
                  Raku arrange for international logistic
                </p>
                <p>
                  Settle commission to the promoter's account
                </p>
              </div>
            </div>
          </div>
          <div class="process_item">
            <div class="process_item_title">
              Commission Withdrawal Process
            </div>
            <div class="process_item_content">
              <div class="img_box item2">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6ee0324b9.svg" alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6ec535428.svg  " alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6f0243682.svg" alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6ec535428.svg  " alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6f186a3a8.svg" alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6ec535428.svg  " alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6f2ccdc0a.svg" alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da6ec535428.svg  " alt="">
                <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66da71ee63815.svg" alt="">
              </div>
              <div class="text_box">
                <p>
                  Apply for withdrawal
                </p>
                <p>
                  Raku managers confirmation
                </p>
                <p>
                  Withdrawal balance deduction
                </p>
                <p>
                  Raku arrange for transfer
                </p>
                <p>
                  Confirm the receipt of funds
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FAQ区域 -->
      <div class="faq_content" id="faq">
        <div class="faq_main">
          <div class="faq_title">
            FAQ
          </div>
          <div class="collaps_box">
            <el-collapse v-model="activeNames">
              <el-collapse-item name="1">
                <template slot="title">
                  <span class="header_title_text">
                    1.What is the Rakumart Promoter Plan
                  </span>
                  <!-- 收起按钮 -->
                  <img v-if="activeNames.includes('1')" src="../../assets/jingtaiye-pic/lecturePromotion/close.svg"
                    alt="">
                  <!-- 展开按钮 -->
                  <img v-else src="../../assets/jingtaiye-pic/lecturePromotion/unfold.svg" alt="">
                </template>
                <div>
                  Rakumart Promoter Plan offers an opportunity for promoters to earn commissions by inviting US
                  customers to purchase
                  Chinese products from Rakumart.us. This plan is free to join, allowing you to monetize your audience
                  and earn
                  commissions from each customer who shops on Rakumart.us.

                </div>

              </el-collapse-item>
              <el-collapse-item name="2">
                <template slot="title">
                  <span class="header_title_text">
                    2.How will I know if my application is accepted?
                  </span>
                  <img v-if="activeNames.includes('2')" src="../../assets/jingtaiye-pic/lecturePromotion/close.svg"
                    alt="">
                  <img v-else src="../../assets/jingtaiye-pic/lecturePromotion/unfold.svg" alt="">
                </template>
                <div>
                  Our team will carefully review your application and contact you via a WhatsApp call to discuss the
                  details.
                  Typically, the application process is completed within 5 business days.

                </div>

              </el-collapse-item>
              <el-collapse-item name="3">
                <template slot="title">
                  <span class="header_title_text">
                    3.How are referrals tracked?
                  </span>
                  <img v-if="activeNames.includes('3')" src="../../assets/jingtaiye-pic/lecturePromotion/close.svg"
                    alt="">
                  <img v-else src="../../assets/jingtaiye-pic/lecturePromotion/unfold.svg" alt="">
                </template>
                <div>
                  You could visit Rakumart.us Promoter Management Website, where you can view your customer invitation
                  link, invited
                  customers and order purchased by customers.
                </div>

              </el-collapse-item>
              <el-collapse-item name="4">
                <template slot="title">
                  <span class="header_title_text">
                    4.What is the commission structure?
                  </span>
                  <img v-if="activeNames.includes('4')" src="../../assets/jingtaiye-pic/lecturePromotion/close.svg"
                    alt="">
                  <img v-else src="../../assets/jingtaiye-pic/lecturePromotion/unfold.svg" alt="">
                </template>
                <div>
                  You can earn commissions on the orders that have been shipped for the clients you invited. There is no
                  limit to the
                  number of customers you can invite, and we hope you can earn as much commission as possible.
                </div>

              </el-collapse-item>
              <el-collapse-item name="5">
                <template slot="title">
                  <span class="header_title_text">
                    5.How does payment work?
                  </span>
                  <img v-if="activeNames.includes('5')" src="../../assets/jingtaiye-pic/lecturePromotion/close.svg"
                    alt="">
                  <img v-else src="../../assets/jingtaiye-pic/lecturePromotion/unfold.svg" alt="">
                </template>
                <div>
                  The Rakumart Promoter Plan supports payment by direct deposit to your bank account or PayPal and
                  requires a minimum
                  balance of $10 USD before withdrawals can be made.
                  Please note that all commission payments are made in USD.

                </div>

              </el-collapse-item>

            </el-collapse>
          </div>


        </div>
      </div>
      <!-- 联系我们区域 -->
      <div class="contact_us_container" id="contact">
        <div class="contact_us">
          <div class="contact_us_title">
            <p>
              Contact us
            </p>
            <p>
              You could contact us through the chat window on the right
            </p>
          </div>
          <div class="info_text">
            <div class="info_item">
              <img src="../../assets/jingtaiye-pic/lecturePromotion/email.svg" alt="">
              <span>
                service@rakumart.us
              </span>
            </div>
            <div class="info_item">
              <img src="../../assets/jingtaiye-pic/lecturePromotion/WhatsApp.svg" alt="">
              <span>
                +86 15957125759
              </span>
            </div>
            <div class="info_item">
              <img src="../../assets/jingtaiye-pic/lecturePromotion/skype.svg" alt="">
              <span>
                service@rakumart.us
              </span>
            </div>
            <a href="https://www.rakumart.us/" target="blank">
              https://www.rakumart.us/
            </a>



          </div>
        </div>
      </div>

    </div>
    <!-- 协议弹框 -->
    <el-dialog ref="dialog" :visible.sync="isShowAgreement" width="60%" custom-class="protocal_dialog">
      <div class="main_dialog">
        <h2>
          <strong>
            RAKUMART Authorized Promoter Collaboration Agreement
          </strong>
        </h2>
        <p>Party A: RAKUMART</p>
        <p class="line_box">Party B: Rakumart's promoter</p>
        <p class="line_box">
          Through friendly negotiation, Party A and Party B hereby enter into the following agreement regarding Party A
          authorizing Party B as a RAKUMART promoter and Party B recommending and introducing commercial clients to
          Party A.
        </p>
        <h2>
          <strong>
            Article 1: Client Referral
          </strong>
        </h2>

        <p class="line_box">
          Party B's main responsibility is to introduce commercial clients to RAKUMART's platform operated by Party A.
          Prior
          to clients becoming registered members of Party A, Party B is obliged to provide answers and reasonable advice
          regarding common questions about the platform's nature, features, usage methods, and rules. Party B may use
          the
          title "RAKUMART Authorized Promoter" in public activities, provided it does not violate the terms of this
          agreement.
        </p>
        <h2>
          <strong>
            Article 2: Recommended Business Content
          </strong>
        </h2>
        <p class="line_box">
          Party B adopts various forms of promotional activities to recommend clients, including but not limited to
          blogs,
          email magazines, videos, websites, forums, etc. For the content involved in these activities, such as text,
          images, videos, etc., Party B is required to report and notify Party A for compliance review and approval
          before
          publishing.
        </p>
        <h2>
          <strong>
            Article 3: Referral Commission
          </strong>
        </h2>
        <p class="line_box">
          For goods shipped within one year after customer registration, you can earn a commission of 2% of the total
          amount
          of goods. In the second year, the commission rate is 1.5%, and in the third year, it is 1%.
        </p>
        <h2>
          <strong>
            Article 4: Commission Payment
          </strong>
        </h2>
        <p class="line_box">
          After the completion of a client's order, the corresponding commission percentage of the product amount will
          be
          reflected in Party B's dedicated management page. Party B can check the commission balance at any time and
          initiate a withdrawal request within the available balance range. Upon receiving Party B's withdrawal request,
          Party A will arrange for timely payment. The payment will be made by remitting to a bank account in Party B's
          name
          as designated by Party B, and the remittance fees shall be borne by Party B. Party B is responsible for
          truthfully
          reporting and paying taxes to the tax authorities for the income obtained from this business.
        </p>
        <h2>
          <strong>
            Article 5: Confidentiality Obligations
          </strong>
        </h2>
        <p class="line_box">
          Party B has a confidentiality obligation regarding information disclosed by Party A or any technical,
          operational,
          or other business information and data (including personal information protected by personal information
          protection laws) obtained during the performance of this agreement. Such information shall not be used for
          purposes other than those of this agreement without the prior written consent of Party A, and shall not be
          disclosed to third parties.
        </p>
        <h2>
          <strong>
            Article 6: Agreement Term
          </strong>
        </h2>
        <p class="line_box">
          This agreement is valid for one year from the date of signing by both parties. If neither Party A nor Party B
          proposes the termination of the agreement before the expiration date, it will automatically be extended for
          one
          year.
        </p>
        <h2>
          <strong>
            Article 7: Dispute Resolution
          </strong>
        </h2>
        <p class="line_box">
          If any disputes arise between the parties regarding this agreement, the parties shall first attempt to resolve
          them through amicable negotiations. If no agreement is reached through negotiations and legal recourse is
          necessary, the dispute shall be submitted to a court or relevant legal institution for judgment.
        </p>
        <h2>
          <strong>
            Article 8: Miscellaneous
          </strong>
        </h2>
        <p>
          Matters not stipulated in this agreement shall be separately agreed upon by Party A and Party B in an
          additional
          agreement. The legal effect of the additional agreement shall be equivalent to that of this agreement.
        </p>

      </div>
    </el-dialog>
    <!-- 固定导航盒子 -->
    <!-- <div id="navId" class="fixed_nav">
      <ul>
        <li v-for="(item, index) in navList" :key="item.id" :class="{ navActived: navIndex == index }"
          @click="toAchor(item, index)">
          {{ item.title }}
        </li>
      </ul>
    </div> -->
    <Foot />
    <sideBar />
  </div>
</template>
<script>
// import Header from './components/header.vue'
import Header from '../../components/head/HomePageTop.vue'
import Foot from "../../components/foot/Foot.vue";
import sideBar from "../../components/homePage/sideBar.vue";

export default {
  components: {
    Foot,
    Header,
    sideBar,
  },
  mounted() {
    window.addEventListener('scroll', this.throttle(this.handleScroll, 100))
    //监听页面滚动
    const targetdom = document.getElementById('cooperation')
    if (targetdom) {
      this.observeElement(targetdom)
    }
    //process
    const processdom = document.getElementById('process')
    if (processdom) {
      this.observeElement(processdom)
    }
    //faq
    const faqdom = document.getElementById('faq')
    if (faqdom) {
      this.observeElement(faqdom)
    }
    //contact
    const contactdom = document.getElementById('contact')
    if (contactdom) {
      this.observeElement(contactdom)
    }
    window.addEventListener('resize', this.resizeIframe)
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.resizeIframe)
  },
  data() {
    return {
      isFixed: false,
      isShowAgreement: false,
      carouselListImg: [
        {
          id: 1,
          url: "https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66e3ed8c74f23.jpg"
        },
        {
          id: 2,
          url: 'https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66e3edc0cba25.jpg'
        },
        {
          id: 3,
          url: "https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66e3edd067df4.jpg"
        },
      ],
      activeNames: '',
      navList: [
        {
          id: 1,
          title: 'Advantages of cooperation',
          achor: 'cooperation'
        },
        {
          id: 2,
          title: 'Cooperation process',
          achor: 'process'
        },
        {
          id: 3,
          title: 'FAQ',
          achor: 'faq'
        },
        {
          id: 4,
          title: 'Contact us',
          achor: 'contact'
        },
      ],
      navIndex: null,
      isPlay: true
    };
  },
  methods: {
    resizeIframe() {
      const iframe = document.getElementById('ifrId');
      const windowWidth = window.innerWidth;
      // 你可以根据分辨率设置 iframe 的宽度和高度

      if (windowWidth < 1400) {
        iframe.style.width = '448px';
        iframe.style.height = '252px';
        iframe.style.borderRadius = '20px';
      } else {
        iframe.style.width = '560px';
        iframe.style.height = '315px';
        iframe.style.borderRadius = '20px';
      }
    },
    toRegister() {
      window.open('https://promote.rakumart.us/#/training/promote/register1?token=eyJsYW5ndWFnZSI6ImVzIiwidXNlcl9pZCI6MTAxNywicHJvbW90ZV90ZWwiOiIwNDMtMTIzNC01Njc4IiwicHJvbW90ZV9lbWFpbCI6InNlcnZpY2VAcmFrdW1hcnQuY28ua3IiLCJwcm9tb3RlX25hbWUiOiJcdTZiMjdcdTZkMzJcdTdiYTFcdTc0MDZcdTdhZWYifQ%3D%3D', '_blank')
    },

    toAchor(item, index) {
      this.navIndex = index
      //锚点跳转
      let anchor = document.querySelector(`#${item.achor}`)
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
    },
    throttle(fn, delay) {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        if (!timer) {
          timer = setTimeout(function () {
            fn.apply(context, args);
            timer = null;
          }, delay);
        }
      }
    },
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let targetDom = document.getElementById('navId')
      if (scrollTop >= 300) {
        targetDom.style.position = 'fixed'
        targetDom.style.left = '10px'
        targetDom.style.top = '58%'
      } else {
        targetDom.style.position = 'absolute'
        targetDom.style.left = '10px'
        targetDom.style.top = '0'
      }
    },
    // 定义回调函数
    observeElement(element, options = {},) {
      // 默认的选项，包括交叉比例阈值（0.1表示元素至少10%在视口中）  
      const defaultOptions = {
        root: null, // 使用视口作为根  
        rootMargin: '0px', // 根边界  
        threshold: 0.3, // 交叉比例阈值  
      };
      // 合并默认选项和提供的选项
      const observerOptions = { ...defaultOptions, ...options };
      // 创建一个新的 Intersection Observer 实例  
      const observer = new IntersectionObserver(([entry]) => {
        // 当元素的交叉比例达到或超过阈值时  
        if (entry.isIntersecting) {
          if (element.id == 'cooperation') {
            this.navIndex = 0
          } else if (element.id == 'process') {
            this.navIndex = 1
          } else if (element.id == 'faq') {
            this.navIndex = 2
          } else if (element.id == 'contact') {
            this.navIndex = 3
          }
        } else {
          this.navIndex = null

        }
      }, observerOptions);

      // 开始观察目标元素  
      observer.observe(element);
    }

  },
};
</script>
<style lang="scss">
.protocal_dialog {
  height: 80%;
  border-radius: 6px;

  .main_dialog {
    height: 600px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }
  }
}
</style>
<style lang="scss" scoped>
.promotion_container {
  width: 100%;


  .main_container {
    // max-width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    margin-top: -146px;

    .top_bg {
      max-width: 1920px;
      height: 48vw;
      background: url('https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d83a1929db0.png') no-repeat;
      background-size: 100% 100%;

      .top_bg_main {
        width: 67.7vw;
        // width: 1300px;
        height: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 100px;

        .left_text {
          // width: 37vw;
          // width: 707px;

          p:nth-child(1) {
            font-weight: bold;
            font-size: 4.1vw;
            // font-size: 80px;
            color: #222222;
            // line-height: 4.7vw;
            text-align: left;
            font-style: normal;

          }

          p:nth-child(2) {
            font-weight: 400;
            // font-size: 1.2vw;
            font-size: 24px;
            color: #222222;
            // line-height: 1.5vw;
            text-align: left;
            font-style: normal;
            // margin: 2vw 0 3vw 0;
            margin: 40px 0 60px 0;

            span {
              font-weight: bold;
            }
          }

          .el-button {
            width: 8.3vw;
            height: 3vw;
            background: #FF730B;
            border-radius: 0.5vw;
            font-weight: 600;
            font-size: 1.2vw;
            color: #FFFFFF;
            border: none;
          }
        }

        .right_img {
          // width: 27vw;
          // height: 25vw;
          width: 562px;
          height: 560px;
          // flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;

          }
        }
      }
    }

    .table_container {
      max-width: 1920px;
      position: relative;

      .fixed_nav {
        // width: 14vw;
        // height: 10vw;
        width: 254px;
        height: 200px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
        border-radius: 20px;
        position: absolute;
        left: 10px;
        top: 0;
        z-index: 80;



        ul {
          background-color: #fff;
          border-radius: 20px;
          width: 100%;
          height: 100%;
          // padding: 1.5vw;
          padding: 30px 20px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: space-between;


          li {
            width: 100%;
            font-weight: 400;
            // font-size: 0.8vw;
            font-size: 16px;
            color: #888888;

            &:hover {
              cursor: pointer;
              font-weight: 600;
              // font-size: 0.8vw;
              font-size: 16px;
              color: #222222;
            }
          }

          .navActived {
            font-weight: 600;
            // font-size: 0.8vw;
            font-size: 16px;
            color: #222222;
          }
        }
      }

      .table_main {
        // width: 73vw;
        // max-width: 73vw;
        width: 62.5vw;
        margin: auto;
        padding: 6vw 0;
        padding-bottom: 4vw;

        .main_title {
          font-weight: bold;
          font-size: 2.5vw;
          color: #222222;
          line-height: 3vw;
          text-align: center;
          margin-bottom: 4vw;
        }

        .process_box_title {
          margin-bottom: 0;
        }

        .first_table {

          display: flex;
          align-items: center;
          justify-content: space-between;

          img {
            width: 31vw;
            height: 11vw;
          }

          .first_table_left {
            width: 33vw;

            p:nth-child(1) {
              font-weight: 500;
              font-size: 1.6vw;
              color: #222222;
              line-height: 2.5vw;
            }

            p:nth-child(2) {
              font-weight: 400;
              font-size: 1vw;
              color: #222222;
              line-height: 1.2vw;
              margin: 1vw 0 0.5vw 0;
            }

            p:nth-child(3) {
              font-weight: 500;
              font-size: 1vw;
              color: #FF730B;
              line-height: 1.2vw;
            }
          }
        }

        .second_table {
          width: 100%;
          height: 18vw;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
          border-radius: 10px;
          padding: 2vw;
          margin: 3vw 0 4vw 0;


          img {
            width: 100%;
            height: 14vw;
          }
        }

        .third_table {
          width: 62.5vw;

          .scroll_box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            ::v-deep .el-carousel {
              padding: 10px;

              .el-carousel__container {
                width: 29vw;
                height: 19.8vw;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
                border-radius: 20px;

                .el-carousel__item {
                  width: 90%;
                  height: 90%;
                  top: 5%;
                  left: 5%;

                  img {
                    width: 100%;
                    height: 100%;

                  }

                }

              }
            }

            ::v-deep .el-carousel__indicators {
              .el-carousel__indicator {
                button {
                  width: 0.6vw;
                  height: 0.6vw;
                  border-radius: 50%;
                  background: #FF730B;
                  opacity: 0.29;
                }

              }

              .el-carousel__indicator.is-active {
                .el-carousel__button {
                  opacity: 1;

                }
              }
            }

            .right_text {
              width: 30vw;


              p:nth-child(1) {
                font-weight: 500;
                font-size: 1.6vw;
                color: #222222;
                line-height: 2.5vw;
                margin-bottom: 1vw;
              }

              p:nth-child(2) {
                font-weight: 400;
                font-size: 1vw;
                color: #222222;
                line-height: 1.2vw;
              }
            }

          }

          .vedio_box {
            margin-top: 4vw;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .vedio_text {
              width: 33vw;

              p:nth-child(1) {
                font-weight: 500;
                font-size: 1.6vw;
                color: #222222;
                line-height: 2.5vw;
                margin-bottom: 1vw;
              }

              p:nth-child(2) {
                font-weight: 400;
                font-size: 1vw;
                color: #222222;
                line-height: 1.2vw;
              }
            }


            .videoBox {

              box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
              border-radius: 20px;
            }

          }
        }

        .process_item {
          padding-bottom: 2vw;

          .process_item_title {
            text-align: center;
            font-weight: 500;
            font-size: 1.6vw;
            color: #222222;
            line-height: 2.5vw;
            margin: 2vw 0;
          }

          .process_item_content {
            .img_box {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 2vw;

              img {
                width: 6vw;
              }

              img:nth-child(odd) {
                width: 6vw;
              }
            }

            .item2 {
              img:nth-child(even) {
                width: 9.3vw;
              }
            }

            .text_box {
              display: flex;
              justify-content: space-between;
              margin-top: 1vw;

              p {
                width: 10.4vw;
                text-align: center;
                font-weight: 400;
                font-size: 0.9vw;
                color: #222222;
                line-height: 1vw;
              }
            }
          }
        }


      }
    }

    .faq_content {
      max-width: 1920px;
      background: #F4F9FF;

      .faq_main {
        width: 73vw;
        margin: auto;
        padding: 6vw 0;

        .faq_title {
          font-weight: bold;
          font-size: 2.5vw;
          color: #222222;
          line-height: 3vw;
          text-align: center;
          margin-bottom: 2vw;
        }

        .collaps_box {
          width: 46vw;
          margin: auto;

          .el-collapse {
            border: none;

            ::v-deep .el-collapse-item {
              margin-bottom: 1vw;

              .el-collapse-item__header {
                width: 46vw;
                height: 5.7vw;
                background: #FFFFFF;
                border-radius: 10px;
                justify-content: space-between;
                border-bottom: none;
                padding: 0 2vw;


                .header_title_text {
                  font-weight: 500;
                  font-size: 1.2vw;
                  color: #222222;
                  line-height: 1.5vw;

                }

                img {
                  width: 2vw;
                  height: 2vw;
                }

                .el-collapse-item__arrow {
                  display: none;
                }
              }

              .el-collapse-item__wrap {
                border-radius: 0 0 10px 10px;
                padding: 0 2vw;
                border: none;

                .el-collapse-item__content {
                  width: 36.5vw;
                  font-weight: 400;
                  font-size: 1vw;
                  color: #222222;
                  line-height: 1.2vw;
                  padding-bottom: 2vw;
                }

              }
            }

            ::v-deep .el-collapse-item.is-active {
              .el-collapse-item__header {
                border-radius: 10px 10px 0 0;
              }

            }
          }
        }
      }
    }

    .contact_us_container {
      max-width: 1920px;
      height: 35vw;
      background: url('https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d83a1929db0.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .contact_us {
        width: 46vw;
        display: flex;
        flex-direction: column;
        align-items: center;

        .contact_us_title {
          margin-bottom: 2vw;

          p:nth-child(1) {
            text-align: center;
            font-weight: bold;
            font-size: 2.5vw;
            color: #222222;
            line-height: 3vw;
            margin-bottom: 1vw;
          }

          p:nth-child(2) {
            text-align: center;
            font-weight: 400;
            font-size: 1vw;
            color: #222222;
            line-height: 1.2vw;
          }
        }

        .info_text {
          width: 21vw;
          height: 13.8vw;
          background: #FFFFFF;
          border-radius: 10px;
          padding: 2vw 0;
          padding-left: 3vw;

          a {
            font-weight: 500;
            font-size: 0.8vw;
            color: #FF730B;
            margin-left: 2vw;
          }

          .info_item {
            margin-bottom: 1.5vw;

            span {

              font-weight: 500;
              font-size: 1vw;
              color: #222222;
            }

            img {
              width: 18px;
              height: 16px;
              margin-right: 5px;
            }
          }
        }
      }
    }


  }

  // .fixed_nav {
  //   width: 14vw;
  //   height: 10vw;
  //   box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  //   border-radius: 20px;
  //   position: fixed;
  //   left: 20px;
  //   top: 50%;
  //   z-index: 80;

  //   ul {
  //     background-color: #fff;
  //     border-radius: 20px;
  //     width: 100%;
  //     height: 100%;
  //     padding: 1.5vw;
  //     display: flex;
  //     align-items: flex-start;
  //     flex-direction: column;
  //     justify-content: space-between;


  //     li {
  //       width: 100%;
  //       font-weight: 400;
  //       font-size: 0.8vw;
  //       color: #888888;

  //       &:hover {
  //         cursor: pointer;
  //         font-weight: 600;
  //         font-size: 0.8vw;
  //         color: #222222;
  //       }
  //     }

  //     .navActived {
  //       font-weight: 600;
  //       font-size: 0.8vw;
  //       color: #222222;
  //     }
  //   }
  // }



  .main_dialog {
    h2 {
      margin-bottom: 20px;
      font-size: 16px;
    }

    .line_box {
      margin-bottom: 20px;
    }
  }

}
</style>
